<template>
    <div>
        <el-table border v-loading="loading" :data="dataList" >
            <el-table-column label="代理商/机构编号" prop="agentNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="代理商/机构名称" prop="userName" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="加收类型" prop="adjustNo" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ dicts.ADJUST_NO[scope.row.adjustNo] }}
                    </template>
            </el-table-column>
            <el-table-column label="调价类型" prop="adjustType" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ dicts.ADJUST_TYPE[scope.row.adjustType] }}
                    </template>
            </el-table-column>
            <el-table-column label="下发收益（%）" prop="rate" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="操作人" prop="updateBy" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="操作时间" prop="updateTime" min-width="140" :show-overflow-tooltip="true"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="160" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text"
                               @click="handleUpdate(scope.row)"
                               v-permission="'AGENT_ADJUST_COST_UPDATE'">修改</el-button>
                    <el-button size="mini" type="text"
                               @click="handleDelete(scope.row)"
                               v-permission="'AGENT_ADJUST_COST_DELETE'">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>

        <CustomForm :dicts="dicts"
                    :on-fresh="showCustomForm"
                    :isAdd="isAdd"
                    :selectRow="selectRow"
                    @on-close="handlerCustomFormOnclose" ></CustomForm>
    </div>
</template>

<script>
    import {SpecialApi} from '@/api';
    import CustomForm from "./CustomForm";
    export default {
        name: "ToolBar",
        components: {CustomForm},
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 20,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false
            }
        },
        mounted() {
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await SpecialApi.agentAdjustCost.list(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            handleStatusChange(row) {
                let text = row.status === "DISABLE" ? "禁用" : "启用";
                this.$confirm("是否确定"+text+"?","警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await SpecialApi.agentAdjustCost.edit(row.id, {
                        state: row.state
                    });
                    if (result.success) {
                        this.getList();
                    }
                }).catch(() => this.getList());
            },
            async handleUpdate(row) {
                this.showCustomForm = true;
                this.selectRow = row;
                this.isAdd = false;
            },
            handleDelete(row){
                this.$confirm("是否确定删除?",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await SpecialApi.agentAdjustCost.remove(row.id);
                    if (result.success) {
                        this.Message.success("删除成功");
                        this.getList();
                    }
                });
            },
        }
    };
</script>

<style scoped>

</style>
